import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";

const initialUserState = {
    user_id: null,
}

const userReducer = (state = initialUserState, action) => {
    switch (action.type) {
        case "SET_USER":
            state = {
                ...state,
                user_id: action.payload,
            };
            break;
        case "LOG_OUT":
            state = {
                ...state,
                user_id: null,
            };
            break;
        default:
            break;
    }
    return state;
}

const store = createStore(combineReducers({ userReducer }));

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

