import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './components/Login';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';

// Styles
import './App.scss';
import './custom.scss';

// Containers
const DefaultLayout = React.lazy(() => import('./DefaultLayout'));
const Calendar = React.lazy(() => import('./BookingCalendar'));

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

class App extends Component {
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/calendar2/:lng?" component={Calendar} />
            <PrivateRoute component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    const jwt = localStorage.token ? jwtDecode(localStorage.token) : {}
    if (jwt.exp > Date.now() / 1000) {
      return <Component {...props} />
    } else {
      localStorage.removeItem('token')
      return <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
    }
  }} />
)

function mapStateToProps(state) {
  return {
    user: state.userReducer,
  }
}

export default connect(mapStateToProps)(App);
