import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Button, Card, CardBody, CardGroup, Col, Container, Form,
    Input, InputGroup, InputGroupAddon, InputGroupText, Row
} from 'reactstrap';
import paradox from '../assets/img/brand/pp.png';

const API = process.env.REACT_APP_API;
const LOGIN = API + '/api/auth';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectToReferrer: false,
            invalidCredentials: false,
            error: false,
        }
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/login' } }
        const { redirectToReferrer } = this.state

        if (redirectToReferrer === true)
            return <Redirect to={from.pathname} />

        if (localStorage.token)
            return <div>Logged in</div>

        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <CardGroup>
                            <Card className="p-4">
                                <center>
                                    <img src={paradox} width="120" height="120" alt="Paradox" />
                                </center>
                                <CardBody>
                                    <Form onSubmit={this.login}>
                                        <p className="text-muted text-center">Sign In to your account</p>
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="icon-user"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="text" placeholder="Email" onChange={this.handleUserNameChange} autoComplete="email" required />
                                        </InputGroup>
                                        <InputGroup className="mb-4">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="icon-lock"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="password" placeholder="Password" onChange={this.handlePasswordChange} autoComplete="password" required />
                                        </InputGroup>
                                        {this.state.invalidCredentials && !this.state.error && <center><small className="help-block text-danger">Invalid email or password.</small></center>}
                                        {this.state.error && <center><small className="help-block text-danger">System error.</small></center>}
                                        <Row className="mt-4">
                                            <Col xs="6">
                                                <Button color="dark" className="px-4">Login</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </CardGroup>
                    </Row>
                </Container>
            </div>
        );
    }

    handleUserNameChange = (e) => {
        this.setState({
            email: e.target.value,
        })
    }

    handlePasswordChange = (e) => {
        this.setState({
            password: e.target.value,
        })
    }

    login = (e) => {
        e.preventDefault();
        this.clearErrors();
        fetch(LOGIN, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: this.state.email,
                password: this.state.password,
            }),
        })
            .then(response => {
                if (response.status === 200)
                    return response.json();

                if (response.status === 500)
                    this.setState({
                        error: true,
                    });
                if (response.status >= 400)
                    this.setState({
                        invalidCredentials: true,
                    });
            })
            .then(data => {
                if (data) {
                    localStorage.setItem("token", data.Token);
                    this.props.logIn(data.Token);
                    this.setState({
                        redirectToReferrer: true,
                    });
                }
            })
            .catch(error => this.setState({
                error: true,
            }));
    }

    clearErrors = () => {
        this.setState({
            invalidCredentials: false,
            error: false,
        });
    }
}

function mapStateToProps(state) {
    return {
        user: state.userReducer,
        api: state.apiReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logIn: (user_id) => {
            dispatch({
                type: "SET_USER",
                payload: user_id,
            });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);